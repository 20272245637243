<template>
  <div>
    <h2 class="mb-2">Events</h2>
    <v-data-table
    v-if="logs.length"
    :headers="logHeaders"
    :items="logs"
    :items-per-page="50"
    item-key="id"
    class="elevation-1"
    sort-desc
    sort-by="createdAt"
    >
        <template v-slot:body="{items}">
            <tbody>
                <template v-for="(item,i) in items">
                    <tr :key="item.id">
                        <td>{{ lastUpdated(item.createdAt) }}</td>
                        <td>{{ capitalize(item.trigger) }}</td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-data-table>    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "events",
  data() {
    return {
      logHeaders: [
        { text: "Date", value: "createdAt", width: "5%"},
        { text: "Event", value: "trigger", width: "20%" }
      ]
    };
  },
  components: {},
  async created() {
    
  },
  computed: {
    ...mapState("sensor", ["logs"])
  },
  methods: {
      lastUpdated(date) {
      let fullDate = new Date(date).toLocaleDateString();
      let todayDate = new Date().toLocaleDateString();
      if (fullDate === todayDate) {
        fullDate = "Today";
      }
      return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  },
};
</script>

<style lang="scss">

</style>